import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import "./CookieBanner.css";
//CookieBanner
const CookieBanner = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const consent = Cookies.get("userConsent");
    if (!consent) {
      setIsVisible(true);
    }
  }, []);

  const acceptCookies = () => {
    Cookies.set("userConsent", "accepted", {
      expires: 365,
      sameSite: "None",
      secure: true,
    });
    setIsVisible(false);
    initializeAnalytics();
  };

  const rejectCookies = () => {
    Cookies.set("userConsent", "rejected", {
      expires: 365,
      sameSite: "None",
      secure: true,
    });
    setIsVisible(false);
    disableAnalytics();
  };

  const initializeAnalytics = () => {
    if (!window.dataLayer) {
      const gtagScript = document.createElement("script");
      gtagScript.src =
        "https://www.googletagmanager.com/gtag/js?id=G-02FK0DEQ8Z";
      gtagScript.async = true;
      document.head.appendChild(gtagScript);

      gtagScript.onload = () => {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          window.dataLayer.push(arguments);
        }
        gtag("js", new Date());
        gtag("config", "G-02FK0DEQ8Z", {
          cookie_flags: "SameSite=None; Secure",
        });
        gtag("config", "G-L5VCQGVBTK", {
          cookie_flags: "SameSite=None; Secure",
        });
      };
    }
  };

  const disableAnalytics = () => {
    window["ga-disable-G-02FK0DEQ8Z"] = true;
    window["ga-disable-G-L5VCQGVBTK"] = true;
    console.log("Google Analytics inaktiverad");
  };

  return (
    isVisible && (
      <div
        className="cookie-banner centered"
        role="dialog"
        aria-labelledby="cookie-banner-title"
        aria-describedby="cookie-banner-description"
      >
        <div className="cookie-banner-text">
          <h2 id="cookie-banner-title" className="sr-only">
            Cookie Preferences
          </h2>
          <p id="cookie-banner-description" style={{ margin: 0 }}>
            Vi använder cookies för att analysera och förbättra din upplevelse.
            Genom att klicka på "Acceptera" samtycker du till vår användning av
            cookies enligt vår{" "}
            <a
              href="/privacy-policy"
              className="cookie-link"
              aria-label="Läs vår integritetspolicy"
            >
              Integritetspolicy
            </a>
            .
          </p>
        </div>
        <div className="cookie-banner-buttons">
          <button
            onClick={rejectCookies}
            className="cookie-button"
            aria-label="Avböj cookies"
          >
            Avböj
          </button>
          <button
            onClick={acceptCookies}
            className="cookie-button"
            aria-label="Acceptera cookies"
          >
            Acceptera
          </button>
        </div>
      </div>
    )
  );
};

export default CookieBanner;
